<template>
<div>
  <div class="container-fluid wrapper-medium mt-3">
    <div class="row mb-3">
      <div class="col-12">
        <b-link class="btn btn-link btn-sm pl-0" to="/dashboard"><span class="icon icon-th-large mr-2 pl-0"></span>Dashboard</b-link>
        <h2 class="anim-load1 mt-2">Organisations management</h2>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium">
    <div class="row mb-5">
      <div class="col-12">
        <div class="card mb-3">
          <div class="card-body">

            <div class="d-md-flex justify-content-between mb-5">
              <h4>Organisations</h4>
              <div>
                 <b-link class="btn btn-warning btn-block" to="/organisations-management/create">Create new organisation</b-link>
              </div>
            </div>
            
            <ListViewer 
              :items="organisations" 
              :perPage="20" 
              btnLabel="View" 
              display="name"
              urlSlug="/organisations-management/"
              urlTarget="id"
              sortBy="name"
              :showSearch="true"
            />
          </div> 
        </div>
      </div>
    </div>
  </div>

  <Footer />
</div>

</template>

<script>
import ListViewer from '@/components/modules/ListViewer';

export default {
  name: 'OrganisationsManager',
  components: {
    ListViewer
  },

  data () {
    return {
      organisations: []
    }
  },

  async created() {
    this.$store.state.appSection = 'appUser'
    await this.getOrganisations()
    this.$store.state.loadingProgress = false
  },

  methods: {
    getOrganisations() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'get', 
        url: this.$api.OrganisationAllGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.organisations = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
        .then(() => {
          this.$store.state.loadingProgress = false
        })
    }
  }
}
</script>
